import React, { Component } from "react";
import { register } from "../../crud/auth.crud";
import * as auth from "../../store/ducks/auth.duck";
import { Formik } from 'formik';
import { connect } from "react-redux";
import { validateRegister } from "../../utils/formValidators";
import { FormControl, FormHelperText, TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import Steps from 'rc-steps';
import "rc-steps/assets/index.css";
import Swal from "sweetalert2";
import { FaFileAlt, FaUserAlt } from 'react-icons/fa';
import { buscaCep, formatCepInput, formatCNPJInput, formatCPFInput, formatTelefoneInput } from "../../utils/Utils";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import BetterSelect from '../../components/BetterSelect';

class Register extends Component {
	constructor() {
		super();

		this.state = {
			loading: false,
			step: 'tipoConta',
			tokenType: '',
			checked: false,
			emp_logo: null,
			stepsNumber: 0,
			numPages: null,
			pageNumber: 1,
		};
	}

	//FUNÇÕES DE PAGINAÇÃO DOS TERMOS DE USO

	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ numPages: numPages });
	}
	changePage = (offSet) => {
		this.setState({ pageNumber: this.state.pageNumber + offSet });
	}
	changePageBack = () => {
		this.changePage(-1);
	}
	changePageNext = () => {
		this.changePage(+1);
	}



	onSubmit = (values) => {
		if (!values.emp_logo) return Swal.fire('Ops!', 'É necessario anexar uma imagem ao registro.', 'error');

		this.setState({ loading: true });

		var formData = new FormData();

		for (var key in values) formData.append(key, values[key]);

		formData.append('empresa_termo', this.state.checked);
		formData.append('emp_tipo_empresa', this.state.tokenType === 'Empresa' ? 'Embarcadora' : 'Transportadora');

		register(formData).then(({ data }) => {
			if (data.errors) return Swal.fire('Ops!', data.errors[0], 'error');

			Swal.fire('Você completou o cadastro.', "Agora aguarde a sua aprovação para poder começar a utilizar a plataforma!", 'success'
			).then((result) => {
				if (result.isConfirmed) window.location.href = '/';
			});
		}).catch(({ response }) => {
			if (response?.data?.errors) {
				if (response?.data?.errors?.email) return Swal.fire('Ops!', 'O e-mail já está sendo utilizado.', 'error');

				return Swal.fire('Ops!', response.data.errors[0], 'error');
			} else {
				return Swal.fire('Ops!', 'Houve um problema. Entre em contato com o suporte.', 'error');
			}
		});

		this.setState({ loading: false });
	}

	getInitialValues = () => {
		return {
			emp_razao_social: '',
			emp_nome_fantasia: '',
			emp_cnpj: '',
			emp_inscricao_estadual: '',
			emp_inscricao_municipal: '',
			emp_cep: '',
			emp_logradouro: "",
			emp_logradouro_numero: '',
			emp_endereco_complemento: '',
			emp_bairro: '',
			emp_municipio: '',
			emp_estado: '',
			email: '',
			emp_telefone: '',
			password: '',
			confirm: '',
			usu_nome: '',
			usu_celular: '',
			usu_cpf: '',
			usu_cep: '',
			usu_bairro: '',
			usu_rua: '',
			usu_numero: '',
			usu_cidade: '',
			usu_uf: '',
			usu_complemento: '',
			emp_logo: '',
			emp_flag_matriz_filial: 'Matriz',
			emp_faturamento: '',
			emp_data_inicio_atividades: '',
			emp_num_filiais: '',
			emp_quantidade_veiculos_proprios: '',
			emp_quantidade_veiculos_agregados: '',
		};
	}

	removerFoto = (setFieldValue) => {
		setFieldValue('emp_logo', null);

		this.setState({ emp_logo: null });
	};

	switchEtapas = () => {
		switch (this.state.step) {
			case 'tipoConta':
				return this.tipoConta();

			case 'termos':
				return this.termos();

			case 'cadastroForm':
				return this.cadastroForm();
			default:
				return;
		}
	}

	tipoConta = () => (
		<div>
			<div className="d-flex arrowLeft align-items-baseline">
				<AiOutlineArrowLeft className="pointer mr-3" size={20} color="#1f4351" onClick={() => this.props.history.goBack()} />

				<h1>Cadastro</h1>
			</div>

			<p className="selectProfile">Selecione abaixo qual o perfil da sua empresa. Você será redirecionado para a área correspondente para completar seu cadastro.</p>

			<div className="boxContainer">
				<div className="box" onClick={() => this.setState({ step: 'termos', tokenType: 'Empresa' })}>
					<div className="imageContainer">
						<img src="/images/register/job.png" width="24" height="22" alt="trabalho" />
					</div>

					<div className="phraseContainer">
						<h3>Embarcadora</h3>

						<p className="m-0">Cadastre sua carga para cotação do frete e inicie um leilão de frete retorno em tempo real.</p>
					</div>

					<div className="pl-2">
						<AiOutlineArrowRight size={20} color="#ff3d1c" />
					</div>
				</div>

				<div className="box" onClick={() => this.setState({ step: 'termos', tokenType: 'Transportadora' })}>
					<div className="imageContainer">
						<img src="/images/register/truck.png" width="24" height="22" alt="caminhão" />
					</div>

					<div className="phraseContainer">
						<h3>Transportadora</h3>

						<p className="m-0">Submeta uma cotação para o frete de interesse e tenha grandes chances de sucesso no leilão das cargas ofertadas pelas empresas.</p>
					</div>

					<div className="pl-2">
						<AiOutlineArrowRight size={20} color="#ff3d1c" />
					</div>
				</div>
			</div>
		</div>
	)

	termos = () => (
		<>
			<div className="d-flex arrowLeft align-items-baseline">
				<AiOutlineArrowLeft className="pointer mr-3" size={20} color="#1f4351" onClick={() => this.setState({ step: 'tipoConta', stepsNumber: 0 })} />

				<h1>Termos de uso</h1>
			</div>

			<p className="selectProfile">Última atualização, 29/11/2020.</p>
			<p className="mt-2 duvidas">Caso tenha alguma dúvida ou problema, entre em contato pelo <b>suporte@fastfrete.com.br</b></p>

			<Steps className="mt-5 stepsNumber" current={this.state.stepsNumber}>
				<Steps.Step icon={<FaFileAlt color="#ff2601" />} title="Termos de uso" />
				<Steps.Step icon={<FaUserAlt color="#ff2601" />} title="Dados básicos" />
			</Steps>

			<div className="my-4" style={{ overflowY: 'scroll', height: 600, backgroundColor: '#f2f2f2' }}>
				<Document file='/pdf/termos.pdf' onLoadSuccess={this.onDocumentLoadSuccess}>
					{/* OPÇÃO DE VISUALIZAÇÃO DE PDF SOMENTE COM O SCROLL
					
					{Array.from(
						new Array(this.state.numPages),
						(el, next)=> (
							<Page 
								key={`page_${next + 1}`}
								pageNumber={next + 1}
							/>
						)
					)
					}
				*/}
					<Page
						pageNumber={this.state.pageNumber}
					/>
				</Document>
			</div>
			<div className='mb-4 d-flex justify-content-center'>
				{this.state.pageNumber > 1 ?
					<button className="btn btn-success-orange" style={{ height: '20px', width: '20px', padding: 0 }} onClick={this.changePageBack}>&lt;</button>
					:
					<button className="btn btn-success-orange" disabled style={{ height: '20px', width: '20px', padding: 0 }}>&lt;</button>
				}
				<span style={{ margin: '0px 10px' }}>Página {this.state.pageNumber} de {this.state.numPages}</span>
				{
					this.state.pageNumber < this.state.numPages ?
						<button className="btn btn-success-orange" onClick={this.changePageNext} style={{ height: '20px', width: '20px', padding: 0 }}>&gt;</button>
						:
						<button className="btn btn-success-orange" disabled style={{ height: '20px', width: '20px', padding: 0 }}>&gt;</button>
				}
			</div>

			<div className='checkboxAgree d-block d-md-flex justify-content-between mb-4'>
				<FormControlLabel label="Declaro que li e aceito o termo de uso." control={<Checkbox checked={this.state.checked} onChange={() => this.setState({ checked: !this.state.checked })} name="remember" color="primary" />} />
				<button className="btn btn-success-orange" type="submit" disabled={this.state.loading || !this.state.checked} onClick={() => this.setState({ step: 'cadastroForm', stepsNumber: 1 })}>Entrar {this.state.loading ? <Spinner animation="border" /> : <AiOutlineArrowRight />}</button>
			</div>
		</>
	)

	cadastroForm = () => {
		return (
			<>
				<div className="d-flex arrowLeft align-items-baseline">
					<AiOutlineArrowLeft className="pointer mr-3" size={20} color="#1f4351" onClick={() => this.setState({ step: 'termos', stepsNumber: 0 })} />

					<h1>Cadastro</h1>
				</div>

				<p className="ml-5 tokenTypeAsset">{this.state.tokenType}</p>
				{/* {console.log(this.state.tokenType)} */}
				<div className="boxForm">
					<Steps className="mt-5 stepsNumber" current={this.state.stepsNumber}>
						<Steps.Step icon={<FaFileAlt color="#ff2601" />} title="Termos de uso" />
						<Steps.Step icon={<FaUserAlt color="#ff2601" />} title="Dados básicos" />
					</Steps>

					<Formik key="register" initialValues={this.getInitialValues()} validate={values => validateRegister(values, this.state.tokenType)} onSubmit={(values) => this.onSubmit(values)}>
						{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
							<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>

								<Row className="mt-5">
									<div className="col-12">
										<p className="tokenTypeAsset">Dados da empresa</p>
									</div>

									<FormControl className="col-lg-6 p-2" fullWidth error={Boolean(touched.emp_razao_social && errors.emp_razao_social)}>
										<TextField type="text" variant="outlined" name="emp_razao_social" label="Razão Social *" onBlur={handleBlur} error={Boolean(touched.emp_razao_social && errors.emp_razao_social)} onChange={handleChange} value={values.emp_razao_social} />
										{touched.emp_razao_social && errors.emp_razao_social && <FormHelperText>{errors.emp_razao_social}</FormHelperText>}
									</FormControl>

									<FormControl className="col-lg-6 p-2" fullWidth error={Boolean(touched.emp_nome_fantasia && errors.emp_nome_fantasia)}>
										<TextField type="text" variant="outlined" name="emp_nome_fantasia" label="Nome Fantasia *" onBlur={handleBlur} error={Boolean(touched.emp_nome_fantasia && errors.emp_nome_fantasia)} onChange={handleChange} value={values.emp_nome_fantasia} />
										{touched.emp_nome_fantasia && errors.emp_nome_fantasia && <FormHelperText>{errors.emp_nome_fantasia}</FormHelperText>}
									</FormControl>
								</Row>
								<Row className="mt-0">
									<FormControl className="col-lg-12 p-2" fullWidth>
										<BetterSelect variant="outlined" name="emp_flag_matriz_filial" label="Matriz/Filial? *" labelWidth={140} value={values.emp_flag_matriz_filial}
											onChange={handleChange} onBlur={handleBlur}>
											<option value='Matriz'>Matriz</option>
											<option value='Filial'>Filial</option>
										</BetterSelect>
									</FormControl>
								</Row>
								<Row className="mt-0">
									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.emp_cnpj && errors.emp_cnpj)}>
										<TextField type="text" variant="outlined" name="emp_cnpj" label="CNPJ *" inputProps={{ maxLength: 18 }} onBlur={handleBlur} error={Boolean(touched.emp_cnpj && errors.emp_cnpj)} onChange={e => handleChange(formatCNPJInput(e))} value={values.emp_cnpj} />
										{touched.emp_cnpj && errors.emp_cnpj && <FormHelperText>{errors.emp_cnpj}</FormHelperText>}
									</FormControl>

									<FormControl className="col-lg-4 p-2" fullWidth>
										<TextField type="text" variant="outlined" name="emp_inscricao_estadual" label="Inscrição Estadual" onBlur={handleBlur} onChange={handleChange} value={values.emp_inscricao_estadual} />
									</FormControl>

									<FormControl className="col-lg-4 p-2" fullWidth>
										<TextField type="text" variant="outlined" name="emp_inscricao_municipal" label="Inscrição Municipal" onBlur={handleBlur} onChange={handleChange} value={values.emp_inscricao_municipal} />
									</FormControl>
								</Row>

								<Row>
									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.emp_cep && errors.emp_cep)}>
										<TextField type="text" variant="outlined" name="emp_cep" label="CEP *" inputProps={{ maxLength: 9 }} onBlur={(e) => handleBlur(buscaCep(e, setFieldValue, 'emp_logradouro', 'emp_bairro', 'emp_municipio', 'emp_estado'))} onChange={(e) => handleChange(formatCepInput(e))} error={Boolean(touched.emp_cep && errors.emp_cep)} value={values.emp_cep} />
										{touched.emp_cep && errors.emp_cep && <FormHelperText>{errors.emp_cep}</FormHelperText>}
									</FormControl>

									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.emp_logradouro && errors.emp_logradouro)}>
										<TextField type="text" variant="outlined" name="emp_logradouro" label="Logradouro *" onBlur={handleBlur} error={Boolean(touched.emp_logradouro && errors.emp_logradouro)} onChange={handleChange} value={values.emp_logradouro} />
										{touched.emp_logradouro && errors.emp_logradouro && <FormHelperText>{errors.emp_logradouro}</FormHelperText>}
									</FormControl>

									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.emp_logradouro_numero && errors.emp_logradouro_numero)}>
										<TextField type="text" variant="outlined" name="emp_logradouro_numero" label="Número *" onBlur={handleBlur} error={Boolean(touched.emp_logradouro_numero && errors.emp_logradouro_numero)} onChange={handleChange} value={values.emp_logradouro_numero} />
										{touched.emp_logradouro_numero && errors.emp_logradouro_numero && <FormHelperText>{errors.emp_logradouro_numero}</FormHelperText>}
									</FormControl>
								</Row>

								<Row className="mt-0">
									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.emp_bairro && errors.emp_bairro)}>
										<TextField type="text" variant="outlined" name="emp_bairro" label="Bairro *" onBlur={handleBlur} error={Boolean(touched.emp_bairro && errors.emp_bairro)} onChange={handleChange} value={values.emp_bairro} />
										{touched.emp_bairro && errors.emp_bairro && <FormHelperText>{errors.emp_bairro}</FormHelperText>}
									</FormControl>

									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.emp_municipio && errors.emp_municipio)}>
										<TextField type="text" variant="outlined" name="emp_municipio" label="Município *" onBlur={handleBlur} error={Boolean(touched.emp_municipio && errors.emp_municipio)} onChange={handleChange} value={values.emp_municipio} />
										{touched.emp_municipio && errors.emp_municipio && <FormHelperText>{errors.emp_municipio}</FormHelperText>}
									</FormControl>

									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.emp_estado && errors.emp_estado)}>
										<TextField type="text" variant="outlined" name="emp_estado" label="UF *" onBlur={handleBlur} error={Boolean(touched.emp_estado && errors.emp_estado)} onChange={handleChange} value={values.emp_estado} />
										{touched.emp_estado && errors.emp_estado && <FormHelperText>{errors.emp_estado}</FormHelperText>}
									</FormControl>
								</Row>

								<Row className="mt-0">
									<FormControl className="col-lg-12 p-2" fullWidth>
										<TextField type="text" variant="outlined" name="emp_endereco_complemento" label="Complemento" onBlur={handleBlur} onChange={handleChange} value={values.emp_endereco_complemento} />
									</FormControl>
								</Row>

								{this.state.tokenType === 'Transportadora' &&
									<>
										<hr className="my-5" />
										<Row className="mt-0 mt-lg-5">
											<div className="col-12">
												<p className="tokenTypeAsset">Dados adicionais</p>
											</div>

											<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.emp_faturamento && errors.emp_faturamento)}>
												<TextField type="number" variant="outlined" name="emp_faturamento" label="Faturamento *" onBlur={handleBlur} error={Boolean(touched.emp_faturamento && errors.emp_faturamento)} onChange={handleChange} value={values.emp_faturamento} />
												{touched.emp_faturamento && errors.emp_faturamento && <FormHelperText>{errors.emp_faturamento}</FormHelperText>}
											</FormControl>

											<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.emp_data_inicio_atividades && errors.emp_data_inicio_atividades)}>
												<TextField type="date" variant="outlined" InputLabelProps={{ shrink: true }} name="emp_data_inicio_atividades" label="Data inicio atividades *" onBlur={handleBlur} error={Boolean(touched.emp_data_inicio_atividades && errors.emp_data_inicio_atividades)} onChange={handleChange} value={values.emp_data_inicio_atividades} />
												{touched.emp_data_inicio_atividades && errors.emp_data_inicio_atividades && <FormHelperText>{errors.emp_data_inicio_atividades}</FormHelperText>}
											</FormControl>

											<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.emp_num_filiais && errors.emp_num_filiais)}>
												<TextField type="number" variant="outlined" name="emp_num_filiais" label="Nº Filiais *" onBlur={handleBlur} error={Boolean(touched.emp_num_filiais && errors.emp_num_filiais)} onChange={handleChange} value={values.emp_num_filiais} />
												{touched.emp_num_filiais && errors.emp_num_filiais && <FormHelperText>{errors.emp_num_filiais}</FormHelperText>}
											</FormControl>

											<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.emp_quantidade_veiculos_proprios && errors.emp_quantidade_veiculos_proprios)}>
												<TextField type="number" variant="outlined" name="emp_quantidade_veiculos_proprios" label="Quantidade veiculos proprios *" onBlur={handleBlur} error={Boolean(touched.emp_quantidade_veiculos_proprios && errors.emp_quantidade_veiculos_proprios)} onChange={handleChange} value={values.emp_quantidade_veiculos_proprios} />
												{touched.emp_quantidade_veiculos_proprios && errors.emp_quantidade_veiculos_proprios && <FormHelperText>{errors.emp_quantidade_veiculos_proprios}</FormHelperText>}
											</FormControl>

											<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.emp_quantidade_veiculos_agregados && errors.emp_quantidade_veiculos_agregados)}>
												<TextField type="number" variant="outlined" name="emp_quantidade_veiculos_agregados" label="Quantidade veiculos agregados *" onBlur={handleBlur} error={Boolean(touched.emp_quantidade_veiculos_agregados && errors.emp_quantidade_veiculos_agregados)} onChange={handleChange} value={values.emp_quantidade_veiculos_agregados} />
												{touched.emp_quantidade_veiculos_agregados && errors.emp_quantidade_veiculos_agregados && <FormHelperText>{errors.emp_quantidade_veiculos_agregados}</FormHelperText>}
											</FormControl>


										</Row>
									</>
								}

								<hr className="my-5" />

								<Row className="mt-0 mt-lg-5">
									<div className="col-12">
										<p className="tokenTypeAsset">Dados do responsável</p>
									</div>

									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.email && errors.email)}>
										<TextField type="email" variant="outlined" name="email" label="E-mail *" onBlur={handleBlur} error={Boolean(touched.email && errors.email)} onChange={handleChange} value={values.email} />
										{touched.email && errors.email && <FormHelperText>{errors.email}</FormHelperText>}
									</FormControl>

									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.usu_nome && errors.usu_nome)}>
										<TextField type="text" variant="outlined" name="usu_nome" label="Nome *" onBlur={handleBlur} error={Boolean(touched.usu_nome && errors.usu_nome)} onChange={handleChange} value={values.usu_nome} />
										{touched.usu_nome && errors.usu_nome && <FormHelperText>{errors.usu_nome}</FormHelperText>}
									</FormControl>

									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.emp_telefone && errors.emp_telefone)}>
										<TextField type="text" variant="outlined" name="emp_telefone" label="Telefone *" onBlur={handleBlur} inputProps={{ maxLength: 15 }} error={Boolean(touched.emp_telefone && errors.emp_telefone)} onChange={e => handleChange(formatTelefoneInput(e))} value={values.emp_telefone} />
										{touched.emp_telefone && errors.emp_telefone && <FormHelperText>{errors.emp_telefone}</FormHelperText>}
									</FormControl>
								</Row>

								<Row className="mt-0">

									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.usu_cpf && errors.usu_cpf)}>
										<TextField type="text" variant="outlined" name="usu_cpf" label="CPF *" onBlur={handleBlur} inputProps={{ maxLength: 14 }} error={Boolean(touched.usu_cpf && errors.usu_cpf)} onChange={e => handleChange(formatCPFInput(e))} value={values.usu_cpf} />
										{touched.usu_cpf && errors.usu_cpf && <FormHelperText>{errors.usu_cpf}</FormHelperText>}
									</FormControl>

									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.password && errors.password)}>
										<TextField type="password" variant="outlined" name="password" label="Senha *" onBlur={handleBlur} error={Boolean(touched.password && errors.password)} onChange={handleChange} value={values.password} />
										{touched.password && errors.password && <FormHelperText>{errors.password}</FormHelperText>}
									</FormControl>

									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.confirm && errors.confirm)}>
										<TextField type="password" variant="outlined" name="confirm" label="Confirme sua senha *" onBlur={handleBlur} error={Boolean(touched.confirm && errors.confirm)} onChange={handleChange} value={values.confirm} />
										{touched.confirm && errors.confirm && <FormHelperText>{errors.confirm}</FormHelperText>}
									</FormControl>
								</Row>

								<Row className="mt-0">

									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.usu_cep && errors.usu_cep)}>
										<TextField type="text" variant="outlined" name="usu_cep" label="CEP *" onBlur={(e) => handleBlur(buscaCep(e, setFieldValue, 'usu_rua', 'usu_bairro', 'usu_cidade', 'usu_uf'))} onChange={(e) => handleChange(formatCepInput(e))}  inputProps={{ maxLength: 9 }} error={Boolean(touched.usu_cep && errors.usu_cep)} value={values.usu_cep} />
										{touched.usu_cep && errors.usu_cep && <FormHelperText>{errors.usu_cep}</FormHelperText>}
									</FormControl>

									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.usu_rua && errors.usu_rua)}>
										<TextField type="text" variant="outlined" name="usu_rua" label="Rua *" onBlur={handleBlur} error={Boolean(touched.usu_rua && errors.usu_rua)} onChange={handleChange} value={values.usu_rua} />
										{touched.usu_rua && errors.usu_rua && <FormHelperText>{errors.usu_rua}</FormHelperText>}
									</FormControl>

									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.usu_bairro && errors.usu_bairro)}>
										<TextField type="text" variant="outlined" name="usu_bairro" label="Bairro *" onBlur={handleBlur} error={Boolean(touched.usu_bairro && errors.usu_bairro)} onChange={handleChange} value={values.usu_bairro} />
										{touched.usu_bairro && errors.usu_bairro && <FormHelperText>{errors.usu_bairro}</FormHelperText>}
									</FormControl>
								</Row>

								<Row className="mt-0">

									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.usu_cidade && errors.usu_cidade)}>
										<TextField type="text" variant="outlined" name="usu_cidade" label="Cidade *" onBlur={handleBlur} error={Boolean(touched.usu_cidade && errors.usu_cidade)} onChange={handleChange} value={values.usu_cidade} />
										{touched.usu_cidade && errors.usu_cidade && <FormHelperText>{errors.usu_cidade}</FormHelperText>}
									</FormControl>

									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.usu_uf && errors.usu_uf)}>
										<TextField type="text" variant="outlined" name="usu_uf" label="UF *" onBlur={handleBlur} error={Boolean(touched.usu_uf && errors.usu_uf)} onChange={handleChange} value={values.usu_uf} />
										{touched.usu_uf && errors.usu_uf && <FormHelperText>{errors.usu_uf}</FormHelperText>}
									</FormControl>

									<FormControl className="col-lg-4 p-2" fullWidth error={Boolean(touched.usu_numero && errors.usu_numero)}>
										<TextField type="text" variant="outlined" name="usu_numero" label="Número *" onBlur={handleBlur} error={Boolean(touched.usu_numero && errors.usu_numero)} onChange={handleChange} value={values.usu_numero} />
										{touched.usu_numero && errors.usu_numero && <FormHelperText>{errors.usu_numero}</FormHelperText>}
									</FormControl>
								</Row>
								<Row className="mt-0">
									
								<FormControl className="col-lg-12 p-2" fullWidth error={Boolean(touched.usu_complemento && errors.usu_complemento)}>
										<TextField type="text" variant="outlined" name="usu_complemento" label="Complemento " onBlur={handleBlur} error={Boolean(touched.usu_complemento && errors.usu_complemento)} onChange={handleChange} value={values.usu_complemento} />
										{touched.usu_complemento && errors.usu_complemento && <FormHelperText>{errors.usu_complemento}</FormHelperText>}
									</FormControl>
								</Row>


								<Col sm={12} className="d-flex justify-content-end my-4 p-0">
									<input type="file" accept="image/*" name="emp_logo" className="d-none" id="contained-button-file" onChange={(e) => { setFieldValue("emp_logo", e.currentTarget.files[0]); this.setState({ emp_logo: URL.createObjectURL(e.currentTarget.files[0]) }); }} />

									<label htmlFor="contained-button-file">
										<span className="btn btn-success-orange btn-bold d-flex"><i className={`margin-icon fa fa-camera`}></i>Adicionar logo</span>
									</label>
								</Col>

								<Row className="justify-content-center">
									{this.state.emp_logo !== null ?
										<Col sm={8}>
											<div className='imagemUploaderMargem'>
												<a href={this.state.emp_logo} target="_blank" rel="noopener noreferrer">
													<div className='imagemUploaderBackground' style={{ backgroundImage: `url("${this.state.emp_logo}")` }}></div>
												</a>

												<button className="btn btn-remove-styled btn-bold imagemUploaderBotaoRemover" onClick={(e) => { e.preventDefault(); this.removerFoto(setFieldValue); }}>
													<i className="fas fa-times margin-icon" />Remover
												</button>
											</div>
										</Col>
										: null}
								</Row>

								<div className="d-flex justify-content-end my-4">
									<button type="submit" disabled={this.state.loading} className='btn btn-success-orange'>Entrar {this.state.loading ? <Spinner animation="border" /> : <AiOutlineArrowRight />}</button>
								</div>
							</form>
						)}
					</Formik>
				</div>
			</>
		);

	}

	render = () => {
		return (
			<Container fluid className="d-flex p-0 registerContainer">
				<div className="containerPhoto" style={{ backgroundImage: `url(${this.state.step === 'tipoConta' ? `/images/register/caminhaoRegister.png` : `/images/register/termosDeUso.png`})` }} />

				<Col md={9} lg={5} className="formSide">
					{this.switchEtapas()}
				</Col>
			</Container>
		);
	}
}

export default connect(null, auth.actions)(Register);