let url = "";

if (process.env.NODE_ENV === 'development') {
	url = "http://localhost:8000";
	//url = "https://api.v2.fastfrete.com.br";
} else if (process.env.REACT_APP_STAGE === 'homolog') {
	url = "https://dev.api.fastfrete.com.br";
} else {
	url = "https://api.v2.fastfrete.com.br";
}

export const Constants = {
	url,
	baseUrl: url + '/api/web',
	baseUrlEmpresa: url + '/api/web/empresa',
	baseUrlPatio: url + '/api/web/patio',
	baseUrlEmbarcadora: url + "/api/web/embarcadora",
	baseUrlAdmin: url + "/api/web/admin",
	baseUrlTransportadora: url + "/api/web/transportadora",
	whatsappLink: "https://api.whatsapp.com/send?phone=554188882660&text=Ol%C3%A1!%20",
};

